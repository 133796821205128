import { Spinner, SpinnerProps, theme } from '@local/web-design-system';
import { Grid, Skeleton, ThemeProvider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { GenericError } from 'src/components/GenericError';
import { INITIAL_STATE } from 'src/state/Drawer';
import {
    ContentLoadingProps,
    defaultMessage,
    defaultTimeout,
    NavBarProps,
    SkeletonType,
} from 'src/components/ContentLoading/ContentLoading.utils';

import { CollarSkeleton } from './CollarSkeleton/CollarSkeleton';
import { sliderStyle } from './ContentLoading.styles';
import { DataGridSkeleton } from './DataGridSkeleton/DataGridSkeleton';
import { HeaderSkeleton } from './HeaderSkeleton/HeaderSkeleton';
import { HomeSkeleton } from './HomeSkeleton/HomeSkeleton';
import { JobSkeleton } from './JobSkeleton/JobSkeleton';
import { ProjectSkeleton } from './ProjectSkeleton/ProjectSkeleton';

export function ContentLoading(props: Readonly<ContentLoadingProps>) {
    const {
        isFailed,
        timeout = defaultTimeout,
        message = defaultMessage,
        skeletonLoading = false,
        fullAppSkeleton = false,
        type,
    } = props;
    const drawerState = INITIAL_STATE.isNavDrawerOpen;

    if (isFailed) {
        return <GenericError />;
    }
    if (skeletonLoading) {
        if (fullAppSkeleton) {
            return (
                <Grid container direction="row" wrap="nowrap">
                    <Grid justifyContent="flex-start">
                        <NavDrawerSkeleton isDrawerOpen={drawerState} />
                    </Grid>
                    <Grid justifyContent="flex-end">
                        <Grid
                            sx={{
                                width: `calc(100vw - ${
                                    drawerState === false ? theme.spacing(7) : theme.spacing(30)
                                })`,
                                height: theme.spacing(8),
                            }}
                            automation-id="title-bar-skeleton"
                        />
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ContentAreaSkeleton />
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <ContentAreaSkeleton />
            </Grid>
        );
    }
    if (type) {
        return (
            <>
                {type === SkeletonType.HomeCards && <HomeSkeleton />}
                {(type === SkeletonType.ProjectCard || type === SkeletonType.ProjectList) && (
                    <ProjectSkeleton type={type} />
                )}
                {type === SkeletonType.DataGrid && <DataGridSkeleton />}
                {type === SkeletonType.Jobs && <JobSkeleton />}
                {type === SkeletonType.Header && <HeaderSkeleton />}
                {(type === SkeletonType.Collar || type === SkeletonType.Configuration) && (
                    <CollarSkeleton />
                )}
            </>
        );
    }
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <UserPortalSpinner {...ApplyTimeoutProps(timeout, message)} />
        </Grid>
    );
}

export function ApplyTimeoutProps(timeout = defaultTimeout, timeoutMessage = defaultMessage) {
    return { timeout, timeoutMessage: <FormattedMessage {...timeoutMessage} /> };
}

export function UserPortalSpinner(props: Readonly<SpinnerProps>) {
    return <Spinner {...ApplyTimeoutProps} {...props} />;
}

export function NavDrawerSkeleton(props: Readonly<NavBarProps>) {
    const { isDrawerOpen } = props;
    const NavWidth = isDrawerOpen ? theme.spacing(30) : theme.spacing(7);
    const TitleWidth = isDrawerOpen ? theme.spacing(17) : theme.spacing(7);
    const TitleHeight = isDrawerOpen ? theme.spacing(5) : theme.spacing(7);
    const TitleMarginLeft = isDrawerOpen ? theme.spacing(6) : '0px';
    const TitleMarginTop = isDrawerOpen ? '15px' : '0px';
    const TitleMarginBottom = isDrawerOpen ? '23px' : theme.spacing(1);
    const SkeletonWidth = isDrawerOpen ? theme.spacing(27) : theme.spacing(7);
    const SkeletonLeft = isDrawerOpen ? theme.spacing(3) : '0px';
    const SkeletonBottom = '1px';

    return (
        <ThemeProvider theme={sliderStyle}>
            <Grid
                container
                direction="column"
                sx={{
                    width: `${NavWidth} !important`,
                    height: '100vh',
                    backgroundColor: theme.palette.secondary.main,
                }}
                automation-id="nav-drawer-skeleton"
            >
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                        position: 'relative' as const,
                        width: TitleWidth,
                        height: `${TitleHeight} !important`,
                        marginBottom: TitleMarginBottom,
                        marginTop: TitleMarginTop,
                        marginLeft: TitleMarginLeft,
                    }}
                />

                <Grid sx={{ width: '100%' }}>
                    {Array(4)
                        .fill(true)
                        .map(() => (
                            <Skeleton
                                key={uuidv4()}
                                variant="rectangular"
                                animation="wave"
                                sx={{
                                    width: SkeletonWidth,
                                    height: `${theme.spacing(7)} !important`,
                                    marginBottom: SkeletonBottom,
                                    marginLeft: SkeletonLeft,
                                }}
                            />
                        ))}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export function ContentAreaSkeleton() {
    return (
        <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
                height: `calc(100vh - ${theme.spacing(8)}) !important`,
                width: '100%',
            }}
        />
    );
}
